/*
=============== 
Variables
===============
*/

:root {
  --color-background: #1E1E1E;
  --color-header-link: #EDB935;
  --color-subheader: #14E162;
  --color-text-primary: #FFFFFF;
  --color-text-secondary: #FFFFFF80;

  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}

h1 {color:var(--color-header-link)}


body {
  background-color: #282c34 !important;
  margin: 0;
}

.container {
  min-height: 100vh; 
  display: flex;
  flex-wrap: wrap;
  overflow: visible !important;
  
}

.sidebar {
  width: 33%;
}

main {
  margin-left: 33%;
  width: calc(100% - 33%);
  overflow: visible !important;
}

@media screen and (max-width: 1000px) {
  .sidebar {
    width: 100%; 
  }
  main {
    margin-top: 100vh;
    margin-left: 0;
    width: 100%;
    /* height: 1000px; */
    overflow-y: scroll; 
    padding-bottom: 4rem;
  }
}
